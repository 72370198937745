<template>
  <v-container :fluid="$vuetify.breakpoint.mdAndDown"
    class="px-10"
  >
    <v-row>
      <v-col>
        <h1 class="mt-5">写メ日記の管理</h1>
        <banner-hint>
          登録した日記コンテンツは写メ日記ページ、ホーム画面、キャストプロフィール画面に表示できます。
        </banner-hint>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12">
        <!-- 新規追加ボタン -->
        <div class="mb-7">
          <v-btn
            depressed
            color="primary"
            @click="addDiary()"
          >日記を追加</v-btn>
        </div>

        <v-card v-if="!diaries.length" class="mb-7" flat>
          <v-card-text>写メ日記が登録されていません。</v-card-text>
        </v-card>

        <v-row>
          <v-col
            :cols="index == 0 ? 12 : 6"
            :md="index == 0 ? 12 : 4"
            :lg="index == 0 ? 12 : 3"
            v-for="(diary, index) in diaries"
            :key="diary.diary_id"
          >
            <v-card
              class="pt-7 pb-5 px-6"
              elevation="1"
            >
              <v-form :ref="'form-diary-' + diary.diary_id">
                <!-- ******************************************** -->
                <!-- キャスト -->
                <!-- ******************************************** -->
                <v-row>
                  <v-col cols="12">
                    <!-- 名前 -->
                    <v-text-field
                      v-if="!diary.create"
                      class="pt-2"
                      v-model="diary.cast_name"
                      label="キャスト名"
                    ></v-text-field>
                    <!-- 選択リスト -->
                    <v-select
                      class="mb-n3"
                      v-if="diary.create"
                      v-model="selectedCastIds[diary.diary_id]"
                      outlined
                      label="キャストを選んでください"
                      prepend-inner-icon="mdi-face-woman"
                      required
                      :items="casts"
                      return-object
                      item-text="name"
                      item-color="primary"
                      :rules="[valiRules.required]"
                    ></v-select>
                  </v-col>
                </v-row>

                <!-- ******************************************** -->
                <!-- 画像 -->
                <!-- ******************************************** -->
                <v-row class="mt-5">
                  <v-col :cols="index == 0 ? 6 : 12">
                    <v-sheet
                      :class="index == 0 ? 'mt-n5' : 'mt-n10'"
                      v-if="diary.image_url"
                    >
                      <!-- サムネイル -->
                      <small>掲載画像</small>
                      <v-img
                        max-height="350"
                        contain
                        :src="diary.image_url"
                      >
                      </v-img>
                    </v-sheet>
                    <!-- インプット -->
                    <v-file-input
                      v-if="diary.create"
                      v-model="updateImages[diary.diary_id]"
                      accept="image/png, image/jpeg"
                      outlined
                      chips
                      show-size
                      required
                      prepend-icon="mdi-image"
                      label="アイキャッチ画像"
                      hint="サイズ上限：1MB"
                      persistent-hint
                      :rules="[valiRules.image]"
                    >
                      <template v-slot:selection="{ text }">
                        <v-chip
                          color="primary"
                          label small
                        >
                          {{ text }}
                        </v-chip>
                      </template>
                    </v-file-input>
                  </v-col>

                  <!-- ******************************************** -->
                  <!-- 本文 -->
                  <!-- ******************************************** -->
                  <v-col :cols="index == 0 ? 6 : 12">
                    <v-textarea
                      v-model="diary.text"
                      :disabled="!diary.create"
                      filled
                      auto-grow
                      counter="300"
                      label="本文"
                      persistent-hint
                      :hint="dateFormat(diary.created_at) + ' 投稿'"
                      :rules="[valiRules.required, valiRules.max300]"
                    ></v-textarea>
                  </v-col>
                </v-row>

                <!-- ボタン -->
                <v-row no-gutters
                  class="justify-end"
                  :class="index == 0 ? 'mt-5' : 'mt-0'"
                >
                  <div>
                    <v-btn
                      v-if="diary.create"
                      depressed small
                      color="primary"
                      @click="createRow(diary)"
                    >投稿</v-btn>
                    <v-btn
                      class="ml-2"
                      text
                      depressed small
                      color="primary"
                      @click="deleteRow(index)"
                    >削除</v-btn>
                  </div>
                </v-row>
              </v-form>
            </v-card>
          </v-col>
        </v-row>

        <!-- もっとボタン -->
        <div class="mt-7">
          <v-btn
            :disabled="disableLoadMore"
            color="primary"
            @click="loadMore"
          >もっと
          </v-btn>
        </div>
      </v-col>
    </v-row>

    <!-- バリデーションアラート -->
    <v-snackbar
      v-model="snackbar.open"
      :timeout="3000"
      :color="snackbar.color"
      top
    >
      {{ snackbar.message }}
    </v-snackbar>

    <!-- ローダー -->
    <loader
      :loading="loading"
      :absolute="false"
    >
      {{ loadingMessage }}
    </loader>
  </v-container>
</template>

<!-- ************************************* -->
<!-- ************* スクリプト ************** -->
<!-- ************************************* -->
<script>
import moment from "moment";
import $literals from '@/literals.js'
import { SITE_API_ENDPOINT } from '@/literals.js'
import { ApiTool, CheckTokenError, ValidationRules } from '@/module.js'
import Loader from '@/components/_Loader.vue'
import BannerHint from "@/components/_BannerHint.vue";

export default {
  components: {
    'loader': Loader,
    'banner-hint': BannerHint,
  },

  props: {
    apiAdmin: {
      type: String,
      required: true
    },
    shopData: {
      type: Object,
      required: true
    }
  },

  data() {
    return {
      maxDiaryId: 0,
      diaries: [],
      updateImages: [],
      casts: [],
      selectedCastIds: [],
      queryLimit: 5,
      queryOffset: 0,
      disableLoadMore: false,
      loading: false,
      loadingMessage: '',
      valiRules: ValidationRules,
      snackbar: {open: false, color: 'primary', message: ''},
      adminApi: new ApiTool(this.apiAdmin, this.shopData),
      publicApi: new ApiTool(SITE_API_ENDPOINT + '/', this.shopData),
    }
  },

  computed: {
    serverToken() {
      return sessionStorage.getItem('serverToken')
    },
    dateFormat() {
      return date => moment(date).format('YYYY/MM/DD HH:mm')
    }
  },

  created() {
    this.adminApi.setToken(this.serverToken)

    this.getData()
    .catch(error => { if (CheckTokenError(error)) this.$emit('reset') })
  },

  methods: {
    async getData() {
      this.loading = true
      this.loadingMessage = $literals.MESSAGE.loaderLoading

      await this.getCasts()

      await Promise.all([
        this.getSiteCastDiary(),

        this.loading = false
      ])
      // this.addDiary()
    },

    //もっと読み込む
    loadMore() {
      this.getSiteCastDiary()
      .catch(error => { if (CheckTokenError(error)) this.$emit('reset') })
    },

    //新規追加
    addDiary() {
      this.diaries.unshift({
        diary_id: ++this.maxDiaryId,
        cast_id: 0,
        text: '',
        image_url: '',
        create: true
      })
    },

    //**************************************************
    //**************************************************
    //                    APIコール
    //**************************************************
    //
    // API req: GET
    //
    getSiteCastDiary() {
      const query = {limit: this.queryLimit, offset: this.queryOffset}

      return this.publicApi.getReqSitePublic('common/diary/', query).then( records => {
        if (!records || !records.length) {
          this.disableLoadMore = true
          return
        }

        //id最大値の取得
        const maxRecordId = Math.max(...records.map( row => parseInt(row.diary_id) ))
        this.maxDiaryId = maxRecordId > this.maxDiaryId ? maxRecordId : this.maxDiaryId

        records.map( row => {
          //キャスト名の取得
          this.casts.map( cast => {
            if (row.cast_id === cast.cast_id) {
              row.cast_name = cast.name
            }
          })
          this.diaries.push(row)
        })

        this.queryOffset = this.queryOffset + this.queryLimit
      })
    },

    //
    // API req: キャストデータ
    //
    getCasts() {
      return this.adminApi.getReqWithAuth("cast/").then( casts => {
        if (!casts || !casts.length) return
        this.casts = casts;
      })
    },

    //**************************************************
    //新規登録
    //**************************************************
    createRow(diary) {
      if (!diary.text || !this.updateImages[diary.diary_id] ||
          !this.$refs['form-diary-' + diary.diary_id][0].validate()
      ) {
        this.snackbar = {...{color:'warning', message: $literals.MESSAGE.validationFormInput, open: true}}
        return
      }

      this.loading = true
      this.loadingMessage = $literals.MESSAGE.loaderInTarnsaction

      const formData = new FormData();
      formData.append('cast_id', this.selectedCastIds[diary.diary_id].cast_id)
      formData.append('text', diary.text)
      formData.append('image_url', this.updateImages[diary.diary_id])

      this.adminApi.apiReqWithData('POST', 'create/site-cast-diary/', formData).then( response => {
        diary.image_url = response.image_url
        // diary.diary_id = response.diary_id
        diary.cast_name = this.selectedCastIds[diary.diary_id].name
        delete this.updateImages[diary.diary_id]
        diary.create = false
        this.queryOffset++

        this.snackbar = {...{color:'success', message: $literals.MESSAGE.successCreateSubmit, open: true}}
      })
      .catch(error => { if (CheckTokenError(error)) this.$emit('reset') })
      .then(() => this.loading = false )
    },

    //**************************************************
    //削除
    //**************************************************
    deleteRow(index) {
      if (this.diaries[index].create) {
        this.diaries.splice(index, 1)
        return
      }

      const apiPath = 'delete/site-cast-diary/' + this.diaries[index].diary_id

      this.adminApi.apiReqWithData('DELETE', apiPath).then(() => {
        this.diaries.splice(index, 1)
        this.queryOffset--

        this.snackbar = {...{color:'success', message: $literals.MESSAGE.successDeleteSubmit, open: true}}
      })
      .catch(error => { if (CheckTokenError(error)) this.$emit('reset') })
    }
  }
}
</script>

<style scoped>
</style>
